.terms-container {
  max-width: 800px;
  margin: 20px auto;
  padding: 20px;
  /* background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); */
}

.terms-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

.terms-container h2 {
  margin-top: 20px;
}

.terms-container p {
  line-height: 1.6;
  margin-bottom: 15px;
}