.success-banner {
    position: fixed;
    top: 20px;
    left: 50%;
    transform: translateX(-50%);
    background-color: #4caf50; /* Green background */
    color: white; /* White text */
    padding: 16px 32px;
    border-radius: 8px;
    z-index: 1000; /* Ensure it's on top of other content */
    font-size: 18px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    transition: opacity 0.5s ease-in-out;
  }
  