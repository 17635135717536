.footer {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 20px;
  background-color: #282c34;
  color: #ffffff;
  border-top: 2px solid #444c56;
  font-family: 'Arial', sans-serif;
  left: 0;
  bottom: 0;
}

.footer-links {
  display: flex;
  justify-content: center;
  gap: 25px;
  margin-bottom: 12px;
  flex-wrap: wrap;
}

.footer-links a {
  color: white;
  text-decoration: none;
  font-size: 18px;
  font-weight: bold;
  transition: color 0.3s ease, transform 0.3s ease;
}

.footer-links a:hover {
  color: #21a1f1;
  transform: scale(1.1);
}

.footer p {
  margin: 0;
  font-size: 14px;
  color: #a9a9a9;
  text-align: center;
}

@media (max-width: 768px) {
  .footer {
    padding: 15px;
  }

  .footer-links a {
    font-size: 16px;
  }
}

@media (max-width: 480px) {
  .footer {
    padding: 10px;
  }

  .footer-links a {
    font-size: 14px;
  }
}