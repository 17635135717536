.rate-course-container {
  max-width: 600px;
  margin: 15px auto;
  padding: 20px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.rate-course-container h1 {
  font-size: 1.8rem;
  text-align: center;
  margin-bottom: 20px;
  color: #333;
}

.rate-course-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  width: 100%;
}

label {
  font-weight: bold;
  margin-bottom: 5px;
  color: #555;
}

input[type="text"],
input[type="number"] textarea,
select {
  width: 83%;
  padding: 12px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 6px;
  box-shadow: inset 0 2px 4px rgba(0, 0, 0, 0.1);
  transition: border-color 0.3s;
}

input[type="text"]:focus,
textarea:focus,
select:focus {
  border-color: #c20f2f;
  outline: none;
}

button {
  padding: 12px 20px;
  font-size: 1rem;
  border: none;
  border-radius: 6px;
  background-color: #c20f2f;
  color: white;
  cursor: pointer;
  transition: background-color 0.3s, transform 0.2s;
}

.btnsubmit {
  padding: 12px 20px;
  width: 20%;
  font-size: 1rem;
  border: none;
  border-radius: 6px;
  background-color: #c20f2f;
  color: white;
  cursor: pointer;
}

button:hover {
  background-color: #c20f2f;
  transform: scale(1.02);
}

button:active {
  transform: scale(0.98);
}

.star-rating {
  display: flex;
  gap: 5px;
}

.star {
  font-size: 24px;
  cursor: pointer;
  color: #ccc;
  transition: color 0.3s, transform 0.2s;
}

.star:hover,
.star.filled {
  color: gold;
  transform: scale(1.2);
}

.tags {
  display: flex;
  flex-wrap: wrap;
  gap: 10px;
}

.tag {
  padding: 5px 15px;
  font-size: 0.9rem;
  border: 1px solid #ccc;
  border-radius: 20px;
  cursor: pointer;
  background-color: #c8c8c8;
  transition: background-color 0.3s, color 0.3s;
}

.tag.active {
  background-color: #c20f2f;
  color: white;
}

.tag.active {
  background-color: #c20f2f;
  color: white;
}

.response-message {
  margin-bottom: 20px;
  font-size: 1rem;
  color: green;
  text-align: center;
}

textarea {
  resize: vertical;
}

/* Responsive Design */
@media (max-width: 768px) {
  .rate-course-container {
    padding: 15px;
  }

  .rate-course-container h1 {
    font-size: 1.5rem;
  }

  button {
    font-size: 0.9rem;
  }

  .star {
    font-size: 20px;
  }
}

@media (max-width: 480px) {
  .rate-course-container {
    padding: 10px;
  }

  .rate-course-container h1 {
    font-size: 1.2rem;
  }

  input[type="text"],
  textarea,
  select {
    font-size: 0.9rem;
  }

  button {
    font-size: 0.8rem;
  }

  .tag {
    font-size: 0.8rem;
    padding: 5px 10px;
  }

  .star {
    font-size: 18px;
  }
}