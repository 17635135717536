.center-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  width: 100%;
  height: 79vh; 
}

.about-container {
  /* margin: 15px; */
  padding: 20px;
  width: 100%; 
  max-width: 800px;
  height: auto; 
  /* background-color: white;
  border-radius: 10px;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.3); */
}

.about-container h1 {
  text-align: center;
  margin-bottom: 20px;
}

.about-container p {
  line-height: 1.6;
  margin-bottom: 20px;
}

.about-container h2 {
  margin-top: 20px;
  margin-bottom: 10px;
}

/* Responsive Design */
@media (max-width: 1200px) {
  .about-container {
    padding: 15px;
  }

  .about-container h1 {
    font-size: 2rem;
  }

  .about-container h2 {
    font-size: 1.5rem;
  }
}

@media (max-width: 992px) {
  .about-container {
    padding: 10px;
  }

  .about-container h1 {
    font-size: 1.8rem;
  }

  .about-container h2 {
    font-size: 1.4rem;
  }

  .about-container p {
    font-size: 1rem;
  }
}

@media (max-width: 768px) {
  .center-container {
    padding: 10px;
    height: auto; 
  }

  .about-container {
    width: 90%;
    padding: 15px;
  }

  .about-container h1 {
    font-size: 1.6rem;
  }

  .about-container h2 {
    font-size: 1.2rem;
  }

  .about-container p {
    font-size: 0.95rem;
  }
}

@media (max-width: 480px) {
  .about-container {
    width: 100%;
    padding: 10px;
  }

  .about-container h1 {
    font-size: 1.4rem;
  }

  .about-container h2 {
    font-size: 1.1rem;
  }

  .about-container p {
    font-size: 0.9rem;
  }
}
