.reviews-main {
    height: 100%;
    width: 100%;
    min-height: 100vh;
}

.my-reviews-container {
    margin: 0 auto;
    padding: 2rem;
    text-align: center;
    flex: 1;
}

.my-reviews-container h2 {
    font-size: 24px;
    font-weight: bold;
    color: #333;
    margin-bottom: 2rem;
}

.reviews-grid {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
    gap: 1.5rem;
}

.review-card {
    background-color: #fff;
    border-radius: 8px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    position: relative;
    text-align: left;
}

.review-card .quote-icon {
    font-size: 24px;
    color: #f5b400;
    position: absolute;
    top: 1rem;
    left: 1.5rem;
}

.course-title {
    font-size: 18px;
    font-weight: bold;
    margin-top: 1rem;
    color: #333;
}

.review-card p {
    font-size: 14px;
    color: #666;
    margin: 0.5rem 0;
}

.review-card button.delete-button {
    background-color: transparent;
    border: none;
    color: #ff4d4f;
    font-size: 18px;
    cursor: pointer;
    position: absolute;
    top: 1rem;
    right: 1.5rem;
}

.no-reviews {
    font-size: 16px;
    color: #888;
}

/* Pop-up overlay */
.popup-overlay {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 9999;
}

/* Pop-up box */
.popup {
    background-color: #ffffff;
    padding: 30px;
    border-radius: 12px;
    width: 500px;
    height: auto;
    box-shadow: 0 8px 20px rgba(0, 0, 0, 0.3);
    position: relative;
    animation: fadeIn 0.3s ease;
    text-align: center;
    transform: scale(0);
    animation: scaleIn 0.3s forwards;
}

/* Pop-up text */
.popup p {
    font-size: 1.2rem;
    margin-bottom: 15px;
    font-weight: 600;
    color: #333;
}

/* Buttons inside the pop-up */
.popup button {
    margin: 8px;
    padding: 8px 16px;
    font-size: 16px;
    cursor: pointer;
    border: none;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.popup button:hover {
    background-color: #c20f2f;
    color: white;
}

.popup button:first-child {
    background-color: #ff4d4d;
    color: white;
}

.popup button:last-child {
    background-color: #4CAF50;
    color: white;
}

.star-rating {
    color: #f5b400;
    /* Star color */
    font-size: 16px;
    letter-spacing: 1px;
}

.star-rating span {
    margin-right: 4px;
}


/* Animations */
@keyframes fadeIn {
    from {
        opacity: 0;
    }

    to {
        opacity: 1;
    }
}

@keyframes scaleIn {
    from {
        transform: scale(0);
    }

    to {
        transform: scale(1);
    }
}