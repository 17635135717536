.popular-classes {
  padding: 20px;
  background-color: #f4f6f8;
  text-align: center;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.heading {
  display: flex;
  padding: 5px;
  flex-direction: column;
  justify-content: start;
  text-align: left;

}

.popular-classes h2 {
  font-size: 28px;
  margin-bottom: 20px;
  color: #333;
}

.classes-list {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  gap: 20px;

}

.class-card {
  background-color: #fff;
  width: 400px;
  border-radius: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  overflow: hidden;
  cursor: pointer;
  transition: transform 0.3s ease, box-shadow 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: left;
}

.class-content {
  padding: 10px;
  display: flex;
  flex-direction: column;
  height: 100%;
  /* gap: 10px; */
}

.class-content h3 {
  font-size: 20px;
  /* margin-bottom: 10px; */
  color: #333;
}

.class-content p {
  font-size: 14px;
  color: #666;
  margin: 4px 0;
}

.ratings {
  padding: 5px;
  min-width: 100%;

}

.rating-item {
  font-size: 4px;
  color: #333;
  /* margin: 4px 0; */
}

.viewbtn {
  background-color: #c20f2f;
  color: #fff;
  width: 40%;
  border: none;
  border-radius: 4px;
  font-size: 14px;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.viewbtn:hover {
  background-color: #c20f2f;
}

.class-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 16px rgba(0, 0, 0, 0.2);
}

@media (max-width: 1200px) {
  .classes-list {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 768px) {
  .classes-list {
    grid-template-columns: 1fr;
  }
}