.details-container {
  width: 100%;
  min-height: 100vh;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding: 20px;
  box-sizing: border-box;
  background-color: #f4f4f4;
}

.class-details-container {
  width: 100%;
  max-width: 1200px;
  padding: 20px;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
  margin: 20px 0;
  font-family: 'Arial', sans-serif;
}

.class-title {
  font-size: 28px;
  font-weight: bold;
  margin-bottom: 20px;
  color: #333;
  text-align: center;
}

.class-info,
.description {
  margin-bottom: 20px;
}

.class-info h3,
.description h3 {
  font-size: 22px;
  margin-bottom: 10px;
  color: #555;
}

.class-info p,
.description p {
  font-size: 16px;
  line-height: 1.6;
  color: #666;
}

.averages h2 {
  font-size: 24px;
  color: #333;
  margin-bottom: 20px;
  /* text-align: center; */
}

.average-item {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 10px;
  font-size: 16px;
}

.average-item span {
  font-weight: bold;
}

.rate-course-button {
  padding: 12px 20px;
  background-color: #4CAF50;
  color: white;
  font-size: 16px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  width: 100%;
  max-width: 200px;
  margin: 20px auto;
  display: block;
  text-align: center;
  transition: background-color 0.3s ease;
}

.rate-course-button:hover {
  background-color: #45a049;
}

.review-list-container {
  display: flex;
  flex-wrap: wrap;
  gap: 20px;
  /* justify-content: space-between; */
}

.review-item-container {
  flex: 1 1 calc(33.333% - 20px);
  max-width: calc(33.333% - 20px);
  box-sizing: border-box;
}

@media (max-width: 768px) {
  .class-title {
    font-size: 24px;
  }

  .class-info p,
  .description p {
    font-size: 14px;
  }

  .rate-course-button {
    max-width: 100%;
  }

  .average-item {
    flex-direction: column;
    align-items: flex-start;
    font-size: 14px;
  }
}

@media (max-width: 480px) {
  .class-title {
    font-size: 20px;
  }

  .rate-course-button {
    font-size: 14px;
  }

  .average-item {
    font-size: 14px;
  }

}

@media (max-width: 1024px) {
  .review-item-container {
    flex: 1 1 calc(50% - 20px);
    max-width: calc(50% - 20px);
  }
}

@media (max-width: 768px) {
  .review-item-container {
    flex: 1 1 100%;
    max-width: 100%;
  }
}