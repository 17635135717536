.app {
  width: 100%;
  font-family: 'Arial', sans-serif;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  background-color: #f4f7fc;
  min-height: 100vh;
  display: flex;
}

.main-content {
  width: 90%;
  max-width: 1150px;
  margin: 50px auto;
  text-align: center;
  padding: 40px;
  background-color: white;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.intro h2 {
  font-size: 2.5rem;
  font-weight: bold;
  color: #333;
  margin-bottom: 20px;
  text-transform: uppercase;
}

.intro p {
  font-size: 1.1rem;
  color: #666;
  margin-bottom: 30px;
}

.search {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  margin-bottom: 30px;
}

.search input {
  width: 100%;
  max-width: 400px;
  padding: 12px;
  font-size: 1rem;
  border: 2px solid #ddd;
  border-radius: 6px;
  box-shadow: 0 2px 6px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.search input:focus {
  outline: none;
  border-color: #c20f2f;
}

.button-group {
  display: flex;
  justify-content: center;
  gap: 20px;
}

.search-btn,
.advanced-search-btn {
  padding: 12px 30px;
  font-size: 1rem;
  border-radius: 6px;
  border: none;
  cursor: pointer;
  transition: background-color 0.3s ease;
}

.search-btn {
  background-color: #c20f2f;
  color: white;
}

.search-btn:hover {
  background-color: #c20f2f;
}

.advanced-search-btn {
  background-color: #c20f2f;
  min-width: 20%;
  color: white;
}

.advanced-search-btn:hover {
  background-color: #c20f2f;
}

.popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
}

.popup-content {
  background-color: white;
  padding: 30px;
  border-radius: 12px;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
  text-align: center;
  max-width: 500px;
  width: 100%;
}

.popup-content h2 {
  font-size: 1.8rem;
  margin-bottom: 10px;
}

.popup-content p {
  font-size: 1rem;
  color: #666;
  margin-bottom: 20px;
}

.close-btn {
  padding: 10px 20px;
  font-size: 1rem;
  border-radius: 6px;
  border: none;
  background-color: #dc3545;
  color: white;
  cursor: pointer;
}

.close-btn:hover {
  background-color: #c82333;
}

@media (max-width: 1200px) {
  .search input {
    width: 90%;
  }

  .button-group {
    display: flex;
    gap: 15px;
    width: 100%;
  }
}

@media (max-width: 992px) {
  .main-content {
    padding: 30px;
  }

  .search input {
    width: 85%;
  }

  .search-btn,
  .advanced-search-btn {
    width: 100%;
    font-size: 1.1rem;
    box-sizing: border-box;
  }
}

@media (max-width: 768px) {
  .search input {
    width: 100%;
    padding: 14px;
    font-size: 1rem;
  }

  .button-group {
    display: flex;
    gap: 15px;
    width: 100%;
  }

  .search-btn,
  .advanced-search-btn {
    width: 100%;
    box-sizing: border-box;
  }
}

@media (max-width: 480px) {
  .search input {
    font-size: 0.9rem;
    padding: 12px;
  }

  .search-btn,
  .advanced-search-btn {
    padding: 12px 25px;
    font-size: 1rem;
    width: 100%;
    box-sizing: border-box;
  }
}